import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ScreenReaderText, Typography } from "@components/Typography";
import { useBruker } from "@features/bruker/queries";
import { Accordion, AccordionItem } from "@fremtind/jkl-accordion-react";
import { faq } from "@features/faq/faq.object";
import ThemeContext from "src/context/ThemeContext";
import { track, Trackingkey } from "src/tracking";
import { isRadgiver } from "src/common/rolle";
import { useOrganizations } from "../../organisasjon.queries";

import "./OrganizationSelector.scss";
import { OrganizationTable } from "../OrganizationTable/OrganizationTable";
import { RelatedServicesList } from "@components/RelatedServicesList/RelatedServicesList";

export const OrganizationSelector = () => {
    const navigate = useNavigate();
    const { distributor } = useContext(ThemeContext);
    const { bruker } = useBruker();
    const { data } = useOrganizations();

    useEffect(() => {
        if (isRadgiver(bruker)) {
            navigate(`/hjem`);
        }
    }, [bruker, navigate, data]);

    return (
        <div className="velg-organisasjon__wrapper">
            <section className="velg-organisasjon__velger">
                {data && data.length ? (
                    <>
                        <ScreenReaderText variant="h2">Dine bedrifter eller organisasjoner</ScreenReaderText>
                        <OrganizationTable />
                    </>
                ) : (
                    <Typography subdued>Vi fant ingen bedrifter eller organisasjoner du har tilgang til.</Typography>
                )}
            </section>
            <section className="velg-organisasjon__sidebar">
                <section className="velg-organisasjon__sidebar__faq">
                    <Typography variant="heading-3" component="h2">
                        Spørsmål om oversikten
                    </Typography>
                    <Accordion>
                        {faq
                            .filter((item) => item.category.includes("Tilganger") || item.category.includes("Privat"))
                            .filter((item) => item.requiresDistributor === distributor || !item.requiresDistributor)
                            .map((question, index) => (
                                <AccordionItem
                                    key={index}
                                    title={question.question}
                                    onClick={() =>
                                        track({
                                            hendelse: Trackingkey.Utvid,
                                            id: question.question,
                                            kategori: "bedriftsvelger"
                                        })
                                    }
                                >
                                    {question.answer}
                                </AccordionItem>
                            ))}
                    </Accordion>
                </section>
                <section className="velg-organisasjon__sidebar__related-links">
                    <Typography variant="heading-3" id="relaterte-tjenester">
                        Relaterte tjenester
                    </Typography>
                    <RelatedServicesList />
                </section>
            </section>
        </div>
    );
};
