import type React from "react";
import type { ModalInstance } from "@fremtind/jkl-modal-react";
import type { AgreementDetails } from "src/model/gen";
import { useEffect, useRef, useState } from "react";
import { AgreementChangesComposer } from "@features/agreements/components/AgreementChanges";
import { GetFormData } from "@features/agreements/components/AgreementChanges/forms/cancel/GetFormData";
import { GetFormType } from "@features/agreements/components/AgreementChanges/GetFormType";
import { useBruker } from "@features/bruker/queries";
import { SecondaryButton } from "@fremtind/jkl-button-react";
import { ContextualMenu, ContextualMenuItem } from "@fremtind/jkl-contextual-menu-react";
import { ChevronDownIcon } from "@fremtind/jkl-icons-react";
import { track, Trackingkey } from "src/tracking";

interface Props {
    agreementDetails: AgreementDetails;
}

export const EditAgreementButton: React.FC<Props> = ({ agreementDetails }) => {
    const dialogRef = useRef<ModalInstance | null>();
    const { bruker } = useBruker();

    const changable = bruker?.radgiverKontaktInfo?.navn === "KUNDESENTER SMB" && agreementDetails.coreSystem === "NICE";
    const agreementSpecific = changable ? GetFormData(agreementDetails) : null;

    const [formAction, setFormAction] = useState<"cancel" | "change">("cancel");
    const [objectName, setObjectName] = useState("");

    const changeAbleAgreementForm = changable ? GetFormType(agreementDetails.productCode) : null;

    const handleFormClick = (objectName: string) => {
        setObjectName(objectName);
        dialogRef.current?.show();
    };

    useEffect(() => {
        handleFormClick(objectName);
    }, [formAction, objectName]);

    if (!changable) return null;
    return (
        <>
            {agreementSpecific?.showCancelForm ? (
                <ContextualMenu
                    className={"agreement-changes-menu"}
                    initialPlacement="bottom-start"
                    triggerElement={
                        <button
                            style={{ display: "flex", alignItems: "center" }}
                            className="jkl-button jkl-button--secondary"
                            type="button"
                            onClickCapture={() => {
                                track({
                                    hendelse: Trackingkey.Knappetrykk,
                                    knappeId: "endre",
                                    dropdown: true
                                });
                            }}
                        >
                            Endre{" "}
                            <span>
                                <ChevronDownIcon
                                    style={{ display: "flex", height: "fit-content", marginLeft: "2px" }}
                                />
                            </span>{" "}
                        </button>
                    }
                >
                    <ContextualMenuItem
                        className="agreement-changes-menu-item"
                        aria-label={`Bestill endring på ${agreementDetails.objects[0].name}`}
                        onClick={() => {
                            handleFormClick(agreementDetails.objects[0].name!);
                            track({
                                hendelse: Trackingkey.Knappetrykk,
                                knappeId: "bestill endring"
                            });

                            if (formAction === "cancel") {
                                setFormAction("change");
                            } else {
                                dialogRef.current?.show();
                            }
                        }}
                    >
                        Bestill endring
                    </ContextualMenuItem>
                    <ContextualMenuItem
                        className="agreement-changes-menu-item"
                        aria-label={`Avslutt ${agreementDetails.objects[0].name}`}
                        onClick={() => {
                            handleFormClick(agreementDetails.objects[0].name!);
                            track({
                                hendelse: Trackingkey.Knappetrykk,
                                knappeId: "avslutt avtale"
                            });
                            if (formAction === "change") {
                                setFormAction("cancel");
                            } else {
                                dialogRef.current?.show();
                            }
                        }}
                    >
                        Avslutt avtale
                    </ContextualMenuItem>
                </ContextualMenu>
            ) : (
                <SecondaryButton
                    title="Endre"
                    aria-label={`Bestill endring på ${agreementDetails.objects[0].name}`}
                    onClick={() => {
                        handleFormClick(agreementDetails.objects[0].name!);
                        track({
                            hendelse: Trackingkey.Knappetrykk,
                            knappeId: "bestill endring",
                            dropdown: false
                        });
                        if (formAction === "cancel") {
                            setFormAction("change");
                        } else {
                            dialogRef.current?.show();
                        }
                    }}
                >
                    Bestill endring
                </SecondaryButton>
            )}
            {changeAbleAgreementForm && (
                <AgreementChangesComposer
                    chosenObject={agreementDetails.objects[0].name!}
                    formAction={formAction}
                    formType={changeAbleAgreementForm}
                    avtaleDetaljer={agreementDetails}
                    dialogRef={dialogRef}
                />
            )}
        </>
    );
};
