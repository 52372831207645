import "./DokumentOversikt.scss";

import { useCallback, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import { track, Trackingkey } from "src/tracking";
import { Side, SideHeader, SideInnhold } from "../components/Side";
import { SkeletonAnimation, SkeletonTable } from "../components/Skeleton";
import { DokumentFiltrering } from "../features/dokumenter/components/DokumentFiltrering/DokumentFiltrering";
import { DokumentOversiktListe } from "../features/dokumenter/components/DokumentOversiktListe";
import { useFetchDokumentListe } from "../features/dokumenter/queries";
import { DocumentDto } from "../model/gen";
import { IHttpError } from "../model/http.typer";

const dagensDato = new Date();
const fjorAaret = new Date();
fjorAaret.setFullYear(dagensDato.getFullYear() - 1);

const byProduktFilter = (produkt: string) => (dokument: DocumentDto) =>
    !produkt ? true : (dokument.productNames ?? []).map((a) => a).includes(produkt);

const byAvtaleNummerFilter = (avtalenummer: string) => (dokument: DocumentDto) =>
    !avtalenummer ? true : (dokument.agreementNumbers ?? []).map((a) => a).includes(avtalenummer);

const byDokumentTypeFilter = (dokumentType: string) => (dokument: DocumentDto) =>
    !dokumentType ? true : dokument.name === dokumentType;

export function DokumentOversikt() {
    const [datoFra, setDatoFra] = useState(fjorAaret);
    const [datoTil, setDatoTil] = useState(dagensDato);

    const query = useFetchDokumentListe(datoFra, datoTil);

    const bedriftsdokumenter = query.data?.entity.filter((bd) => bd.agreementOrganizationType === "BEDRIFT");
    const foreningsdokumenter = query.data?.entity.filter((fd) => fd.agreementOrganizationType === "FORENING_FORBUND");

    const [liste, setListe] = useState("bedrift");
    const harForeningsDokumenter = (query.data?.entity ?? []).some(
        (dokument) => dokument.agreementOrganizationType === "FORENING_FORBUND"
    );
    const harBedriftsDokumenter = (query.data?.entity ?? []).some(
        (dokument) => dokument.agreementOrganizationType === "BEDRIFT"
    );

    const [searchParams, setSearchParams] = useSearchParams();
    const paramsObject = paramsToObject(searchParams);
    const { produktFilter, avtaleFilter, dokumentFilter } = paramsObject;

    const updateFilterValue = (key: string, value: string) => {
        setSearchParams({ ...paramsObject, [key]: value }, { preventScrollReset: true });
    };

    const setDatoFraFilter = (value: Date) => {
        setDatoFra(value);
    };
    const setDatoTilFilter = (value: Date) => {
        setDatoTil(value);
    };

    const setProduktFilter = (value: string) => {
        updateFilterValue("produktFilter", value);
    };
    const setAvtaleFilter = (value: string) => {
        updateFilterValue("avtaleFilter", value);
    };
    const setDokumentFilter = (value: string) => {
        updateFilterValue("dokumentFilter", value);
    };

    const filterDokumentListe = useCallback(
        (liste: DocumentDto[]) =>
            liste
                .filter(byProduktFilter(produktFilter))
                .filter(byAvtaleNummerFilter(avtaleFilter))
                .filter(byDokumentTypeFilter(dokumentFilter)),
        [avtaleFilter, dokumentFilter, produktFilter]
    );

    const dokumenter = useMemo(() => {
        let dokumenter: DocumentDto[] = query.data?.entity ?? [];

        if (harBedriftsDokumenter && harForeningsDokumenter) {
            dokumenter = liste === "bedrift" ? bedriftsdokumenter! : foreningsdokumenter!;
        }

        return filterDokumentListe(dokumenter);
    }, [
        bedriftsdokumenter,
        filterDokumentListe,
        foreningsdokumenter,
        harBedriftsDokumenter,
        harForeningsDokumenter,
        liste,
        query.data?.entity
    ]);

    const decodeFilters = (key: string, value: string) => {
        switch (key) {
            case "datoFra":
                setDatoFra(new Date(Number(value)));
                break;
            case "datoTil":
                setDatoTil(new Date(Number(value)));
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (searchParams) {
            searchParams.forEach((value, key) => {
                decodeFilters(key, value);
            });
        }
        track({
            hendelse: Trackingkey.SeListe,
            type: "dokumenter",
            antall: dokumenter.length,
            liste: dokumenter.map((d) => d.name!)
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const tømFilter = () => {
        setDatoFra(fjorAaret);
        setDatoTil(dagensDato);
        setSearchParams({}, { preventScrollReset: true });
    };

    useEffect(() => {
        const filters = {
            produktFilter,
            avtaleFilter,
            dokumentFilter,
            datoFra: datoFra.valueOf().toString(),
            datoTil: datoTil.valueOf().toString()
        } as { [key: string]: string };

        Object.keys(filters).forEach((key) => {
            if (!filters[key]) {
                delete filters[key];
            }
        });

        setSearchParams(filters, { preventScrollReset: true });
    }, [produktFilter, avtaleFilter, dokumentFilter, datoTil, datoFra, setSearchParams]);

    return (
        <Side>
            <SideHeader
                title="Alle dokumenter"
                lead="Listen viser deg dokumentene for de siste 12 månedene, men du har mulighet til å gå lenger tilbake i
                    tid ved å sette datointervall."
            />
            <SideInnhold query={query} hideFooter={!query.data} loadingText="Henter dokumenter" disableLoader>
                <div>
                    <div className="dokument-oversikt__filtrering">
                        <DokumentFiltrering
                            alleDokumenter={query.data?.entity ?? []}
                            tømFilter={tømFilter}
                            {...{
                                liste,
                                setListe,
                                datoTil,
                                datoFra,
                                initiellDatoFra: fjorAaret,
                                initiellDatoTil: dagensDato,
                                produktFilter,
                                avtaleFilter,
                                dokumentFilter,
                                setDatoFra: setDatoFraFilter,
                                setDatoTil: setDatoTilFilter,
                                setProduktFilter,
                                setAvtaleFilter,
                                setDokumentFilter,
                                harForeningsDokumenter,
                                harBedriftsDokumenter
                            }}
                        />
                    </div>

                    {query.data ? (
                        <AnimatePresence initial={false}>
                            <motion.div
                                key="dokument-liste"
                                initial={{ opacity: 0 }}
                                animate={{ transition: { delay: 0.3 }, opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <DokumentOversiktListe
                                    dokumenter={dokumenter}
                                    tømFilter={tømFilter}
                                    error={query.error as unknown as IHttpError}
                                />
                            </motion.div>
                        </AnimatePresence>
                    ) : (
                        <AnimatePresence>
                            <motion.div
                                key="dokument-liste-skeleton"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <SkeletonAnimation>
                                    <SkeletonTable columns={4} rows={10} />
                                </SkeletonAnimation>
                            </motion.div>
                        </AnimatePresence>
                    )}
                </div>
            </SideInnhold>
        </Side>
    );
}

function paramsToObject(entries: URLSearchParams) {
    const result = {} as { [key: string]: string };
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result;
}
