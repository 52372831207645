import { ClipboardEventHandler, HTMLProps } from "react";
import classNames from "classnames";
import "./Typograhy.scss";

interface TypographyProps
    extends Pick<HTMLProps<HTMLParagraphElement>, "id" | "role" | "className" | "children" | "style" | "hidden"> {
    variant?: keyof typeof defaultVariantMapping;
    component?: "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | "i" | "b" | "dt" | "dd" | "small";
    copyAs?: string | ((selection: string) => string);
    subdued?: boolean;
    bold?: boolean;
    /**
     * Begrenser bredden til 55ch
     */
    prose?: boolean;
    onlyScreenReader?: boolean;
}

export const defaultVariantMapping = {
    body: "p",
    small: "p",
    title: "h1",
    "heading-1": "h1",
    "heading-2": "h2",
    "heading-3": "h3",
    "heading-4": "h4",
    "heading-5": "h5",
    "heading-6": "h6"
};

export const Typography = ({
    variant = "body",
    component,
    className,
    children,
    copyAs,
    subdued,
    bold,
    prose,
    onlyScreenReader,
    ...rest
}: TypographyProps) => {
    const Component = component ? component : (defaultVariantMapping[variant] as keyof JSX.IntrinsicElements);

    const textClass = `jkl-${variant}`;

    const classes = classNames("bm-typography", textClass, subdued, bold, className, onlyScreenReader, {
        "bm-typography--prose": prose,
        "bm-typography--subdued": subdued,
        "jkl-bold": bold,
        "jkl-sr-only": onlyScreenReader
    });

    const handleCopy: ClipboardEventHandler = (copyEvent) => {
        if (!copyAs) {
            return;
        }

        const selection = document.getSelection();

        if (!selection) {
            return;
        }

        let copyData: string;

        if (typeof copyAs === "function") {
            copyData = copyAs(selection.toString());
        } else {
            copyData = copyAs;
        }

        copyEvent.clipboardData.setData("text/plain", copyData);
        copyEvent.preventDefault();
    };

    return (
        <Component className={classes} {...rest} onCopy={handleCopy}>
            {children}
        </Component>
    );
};
