import { Table, TableBody, TableHead, TableHeader, TableRow } from "@fremtind/jkl-table-react";
import { ListeRad, DokumentRad } from "./DokumentListeRad";
import "./DokumentListe.scss";

interface Props {
    kolonner: Array<string>;
    rader: Array<DokumentRad>;
    className?: string;
}

export function DokumentListe({ kolonner, rader }: Props) {
    return (
        <Table fullWidth collapseToList className="dokument-liste__tabell" data-testautoid="dokument-tabell">
            <TableHead sticky>
                <TableRow>
                    {kolonner.map((columnValue, i) => (
                        <TableHeader density="compact" bold={false} key={i}>
                            {columnValue}
                        </TableHeader>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rader.map((rad) => (
                    <ListeRad
                        key={rad.key}
                        rad={rad}
                        kolonner={kolonner}
                        buttonAction={`Åpne ${rad.radInnhold[3]} fra ${rad.radInnhold[0]}`}
                    />
                ))}
            </TableBody>
        </Table>
    );
}
