import { ReactNode, Suspense, lazy } from "react";
import "./SideFooter.scss";
import { FeedbackProps } from "@components/GlobalFeedback";
const GlobalFeedback = lazy(() =>
    import("@components/GlobalFeedback/GlobalFeedback").then((module) => ({ default: module.GlobalFeedback }))
);

export type FooterProps = {
    feedback?: boolean;
    feedbackProps?: FeedbackProps;
    children?: ReactNode;
};

export const SideFooter = ({ feedback = true, feedbackProps, children }: FooterProps) => {
    if (feedback) {
        return (
            <div className="side-footer">
                {feedback && (
                    <Suspense fallback={<></>}>
                        <GlobalFeedback {...feedbackProps} />
                    </Suspense>
                )}
                {children}
            </div>
        );
    }

    return null;
};
