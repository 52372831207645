import { QueryStatus } from "@tanstack/react-query";

import { useBruker } from "../features/bruker/queries";
import { feilkoder, KundeFeilmelding } from "../model/feilkoder.object";
import { AgreementOverview } from "../model/gen";
import { FetchStatus } from "../model/http.typer";

export const useGVerdi = () => {
    const { bruker } = useBruker();

    if (!bruker?.gverdi) {
        throw "Mangler verdi for grunnbeløp";
    }

    return (multiplikator = 1) => bruker.gverdi * multiplikator;
};

export const getDefaultFeilmelding = () => {
    return feilkoder["default"] as KundeFeilmelding;
};

export const getFeilmelding = (index: string, fallbackToDefault = true) => {
    const feilmelding = index.split(".").reduce<Record<string, any>>((p, c) => (p && p[c]) || null, feilkoder);

    if (feilmelding) {
        return feilmelding as KundeFeilmelding;
    }

    // hvis den klarer ikke å få feilmelding return default feilmelding.
    if (fallbackToDefault) {
        return getDefaultFeilmelding();
    }

    return undefined;
};

export const translateQueryStatus = (status: QueryStatus): FetchStatus => {
    const options: { [key: string]: FetchStatus } = {
        error: "rejected",
        idle: "idle",
        loading: "pending",
        success: "resolved"
    };

    return options[status];
};

// fjerner /api/whatever fra login-url
export const sanitizeLoginUrl = (url: URL) => {
    const re = /(bedrift)\/api.*/;
    url.searchParams.set("goto", url.searchParams.get("goto")?.replace(re, "$1") ?? "");

    return url;
};

/**
 * Dyp objektkloning som en backup i påvente av bedre browser support av `structuredClone`.
 * Funksjonen serialiserer og deserialiserer data, så vær varsom ved bruk med ikke-seriaselbare datatyper.
 *
 * @param {object} input Objektet som skal klones.
 * @return {object | undefined } Klonet objekt, undefined dersom klonen feiler.
 */
export const safeClone = <T>(input: T): T | undefined => {
    try {
        //check if input exists;
        if (input === undefined) {
            return undefined;
        }
        const clonedOutput = JSON.parse(JSON.stringify(input)) as T;
        return clonedOutput;
    } catch (error) {
        console.warn(`Noe gikk galt: ${error}`);
        return undefined;
    }
};

export const isDefined = <T>(element: T | null | undefined): element is T => {
    if (element === null) {
        return false;
    }

    if (element === undefined) {
        return false;
    }

    return true;
};

export const isString = (input: any): input is string => {
    if (!isDefined(input)) {
        return false;
    }

    if (typeof input !== "string") {
        return false;
    }

    return true;
};

export async function downloadFile(url: string, filenameResolver: (res: Response) => string) {
    return fetch(url).then((response) =>
        response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = filenameResolver(response);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
    );
}

// ¯\_(ツ)_/¯
// https://stackoverflow.com/questions/8588301/how-to-generate-unique-css-selector-for-dom-element
export const generateSelector = (el: HTMLElement) => {
    const path: string[] = [];
    let parent;

    while ((parent = el.parentNode)) {
        const tag = el.tagName;
        let siblings;

        path.unshift(
            el.id
                ? `#${el.id}`
                : ((siblings = parent.children),
                  [].filter.call(siblings, (sibling: HTMLElement) => sibling.tagName === tag).length === 1
                      ? tag
                      : `${tag}:nth-child(${1 + ([] as HTMLElement[]).indexOf.call(siblings, el)})`)
        );

        el = parent as HTMLElement;
    }
    return `${path.join(" > ")}`.toLowerCase();
};

/**
 * Sammenlign brukerinput og liste med nøkkelord. Brukes til søkefunksjoner.
 */
export function searchByRegex(keywords: string[], match: string) {
    /**
     * Ved å escape input fra bruker unngår vi uforventede sideeffekter.
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
     */
    function escaped(string: string) {
        return new RegExp(string.replaceAll(/[.*+?^${}()|[\]\\]/g, "\\$&"), "gi");
    }

    /**
     * Det er nyttig å sammenligne strenger uten space også, da det ofte kan
     * bli kræsj mellom vanlig space og f.eks &nbsp;.
     */
    function spaceless(string: string) {
        return string.replace(new RegExp(/\s/, "g"), "");
    }

    return keywords.some((keyword) => {
        return keyword.match(escaped(match)) || spaceless(keyword).match(escaped(spaceless(match)));
    });
}

export async function handleCopyToClipboard(content: string) {
    if (!navigator.clipboard) {
        const tempInput = document.createElement("input");
        tempInput.value = content;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    }

    try {
        await navigator.clipboard.writeText(content);
    } catch (err) {
        console.error("Kunne ikke kopiere: ", err);
    }
}

export const isMedlemAvtale = (avtale: AgreementOverview) => {
    return avtale.category === "FORENING_FORBUND";
};

export const isNavneAvtale = (avtale: AgreementOverview) => {
    return avtale.groupInsuranceType === "NAME_BASED";
};
