import { Dialog } from "@components/Dialog";
import { PrimaryButton, SecondaryButton, TertiaryButton } from "@fremtind/jkl-button-react";
import { Feedback } from "@fremtind/jkl-feedback-react";
import type { FollowupQuestion } from "@fremtind/jkl-feedback-react/build/types";
import type { ModalInstance } from "@fremtind/jkl-modal-react";
import { useRef, type ReactNode } from "react";
import { track, Trackingkey } from "src/tracking";

interface FeedbackProps {
    type: "radio" | "smiley";
    /** Spørsmålet som stilles til brukeren */
    label: string;
    /** Hjelpetekst til hovedspørsmålet */
    helpLabel?: string;
    /** Svaralternativer til spørsmålet */
    options: string[];
    /** Dersom du vil stille et åpent spørsmål i tillegg kan du legge det til her */
    followup?: {
        /** Spørsmålet/ene som skal stilles. Kan være av typen radio, checkbox eller text */
        questions: FollowupQuestion[];
        /** Lar deg tilpasse meldingen som kommer når brukeren har svart på spørsmålene.  */
        successMessage?: {
            title: string;
            children: ReactNode;
        };
    };
    /** Lar deg tilpasse meldingen som kommer når brukeren sender inn tilbakemeldingen.  */
    successMessage?: string;
    maxLength?: number;
}
interface FeedbackButtonProps {
    title: string;
    buttonType?: "primary" | "secondary" | "tertiary";
    buttonLabel?: string;
    compact?: boolean;
    feedback?: FeedbackProps;
}

interface FeedbackModalProps {
    title: string;
    dialogRef: React.MutableRefObject<ModalInstance | null>;
    feedback: FeedbackProps;
}

const FeedbackModal = ({ title, dialogRef, feedback }: FeedbackModalProps) => {
    return (
        <Dialog
            dialogRef={(instance) => {
                dialogRef.current = instance;
            }}
            title={title}
            onConfirm={() => {
                setTimeout(() => {
                    dialogRef.current.hide();
                }, 2000);
            }}
            onCancel={() => {
                dialogRef.current?.hide();
            }}
        >
            <Feedback
                type={feedback.type}
                label={feedback.label}
                options={feedback.options.map((option, index) => ({
                    label: option,
                    value: index + 1
                }))}
                followup={
                    feedback.followup && {
                        ...feedback.followup,
                        onSubmit: (value) => {
                            feedback.followup?.questions.map((question, index) => {
                                track({
                                    "Modul tittel": title,
                                    hendelse: Trackingkey.Tilbakemelding,
                                    spørsmålstype: question.type,
                                    spørsmål: question.label,
                                    svar: value[index].value,
                                    verdi: question.type === "smiley" || question.type === "radio" ? index : undefined
                                });
                            });
                        }
                    }
                }
                counter={false}
                successMessage={
                    feedback.successMessage
                        ? { title: "Takk for tilbakemeldingen", children: <>{feedback.successMessage}</> }
                        : { title: "Takk for tilbakemeldingen!", children: <></> }
                }
                onSubmit={(value) => {
                    track({
                        "Modul tittel": title,
                        hendelse: Trackingkey.Tilbakemelding,
                        spørsmålstype: feedback.type,
                        spørsmål: feedback.label,
                        svar: feedback.options[Number(value.feedbackValue)],
                        verdi: Number(value.feedbackValue),
                        kommentar: value.message
                    });
                }}
            />
        </Dialog>
    );
};

export const FeedbackButton: React.FC<FeedbackButtonProps> = ({
    title,
    buttonType = "tertiary",
    buttonLabel = "Gi tilbakemelding",
    compact = true,
    feedback = {
        type: "radio",
        label: "Hva synes du om denne siden?",
        options: ["Veldig dårlig", "Dårlig", "Middels", "Bra", "Veldig bra"]
    }
}) => {
    const dialogRef = useRef<ModalInstance | null>();

    const handleFormClick = () => {
        dialogRef.current?.show();
    };

    const getButtonComponent = () => {
        switch (buttonType) {
            case "primary":
                return PrimaryButton;
            case "secondary":
                return SecondaryButton;
            default:
                return TertiaryButton;
        }
    };

    const ButtonComponent = getButtonComponent();

    return (
        <>
            <ButtonComponent density={compact ? "compact" : "comfortable"} onClick={handleFormClick}>
                {buttonLabel}
            </ButtonComponent>
            <FeedbackModal title={title} dialogRef={dialogRef} feedback={feedback} />
        </>
    );
};
