import { Typography } from "@components/Typography";
import type { AgreementDetails } from "src/model/gen";
import { AgreementCoverageList } from "./AgreementCoverageList";

interface AgreementCoveragesSectionProps {
    agreement: AgreementDetails;
}

export const AgreementCoveragesSection: React.FC<AgreementCoveragesSectionProps> = ({ agreement }) => {
    return (
        <section className="agreement-details__coverages">
            <Typography className="jkl-spacing-l--bottom" variant="heading-2">
                Dekning
            </Typography>
            <AgreementCoverageList objects={agreement.objects} />
        </section>
    );
};
