import { http } from "@features/core";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "src/common/queryClient";
import { useBruker } from "../bruker/queries";
import { DocumentDto, InvoiceDto } from "../../model/gen";
import { toBackendDateFormat } from "src/common/formatting";

const dokumenterQueryKeys = {
    all: (datoFra: string, datoTil: string) => ["dokumenter", datoFra, datoTil]
};

const fakturaQueryKeys = {
    all: ["faktura"],
    allZip: ["faktura", "zip"]
};

const fetchFakturaListe = async (organisasjonsnummer: string) => {
    return await http.getEntity<InvoiceDto[]>(`faktura/${organisasjonsnummer}`);
};

const fetchDokumentListe = async (datoFra: string, datoTil: string) => {
    return await http.getEntity<DocumentDto[]>(`dokumenter/dokumentmeta/${datoFra}/${datoTil}`);
};

export const useFetchDokumentListe = (datoFra?: Date, datoTil?: Date) => {
    const dagensDato = new Date();
    const fjorAaret = new Date();

    fjorAaret.setFullYear(dagensDato.getFullYear() - 1);

    !datoFra && (datoFra = fjorAaret);
    !datoTil && (datoTil = dagensDato);

    return useQuery({
        queryKey: [dokumenterQueryKeys.all(toBackendDateFormat(datoFra), toBackendDateFormat(datoTil))],
        queryFn: () => fetchDokumentListe(toBackendDateFormat(datoFra), toBackendDateFormat(datoTil)),
        staleTime: 1000 * 180
    });
};

export const invoiceQuery = (organizationNumber: string) => {
    return queryClient.fetchQuery({
        queryKey: [fakturaQueryKeys.all],
        queryFn: () => fetchFakturaListe(organizationNumber),
        staleTime: Infinity
    });
};

export const useFetchFakturaListe = () => {
    const { bruker } = useBruker();

    return useQuery({
        queryKey: [fakturaQueryKeys.all],
        queryFn: () => fetchFakturaListe(bruker!.gjeldendeOrganisasjonsnummer!),
        refetchOnWindowFocus: false,
        staleTime: 1000 * 180
    });
};

export type AvtaleOrganisasjonsType = "BEDRIFT" | "FORENING_FORBUND";
