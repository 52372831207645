// implementert gjennom denne guiden: https://medium.com/@msgold/using-usecontext-in-react-a-comprehensive-guide-8a9f5271f7a8
import React from "react";
import { FeedbackButton } from "@components/FeedbackButton";
import "./AgreementPreviewFeedback.scss";

export const AgreementPreviewFeedback: React.FC = () => {
    return (
        <FeedbackButton
            title="Tilbakemelding på de nye avtaledetaljene"
            buttonType="secondary"
            feedback={{
                type: "smiley",
                label: "Hvor fornøyd er du med det nye designet av siden?",
                options: ["Veldig dårlig", "Dårlig", "Middels", "Bra", "Veldig bra"],
                followup: {
                    questions: [
                        {
                            label: "Hva ønsker du å se på denne siden?",
                            type: "checkbox",
                            options: [
                                {
                                    label: "Se årlig eller månedlig pris",
                                    value: "Pris"
                                },
                                {
                                    label: "Avtaledokument",
                                    value: "Avtaledokument"
                                },
                                {
                                    label: "En spesifikk detalj",
                                    value: "En spesifikk detalj"
                                },
                                {
                                    label: "Se eller melde skadesaker",
                                    value: "Skadesaker"
                                },
                                {
                                    label: "Vite hvilken dekning jeg har",
                                    value: "Dekning"
                                },
                                {
                                    label: "Endre forsikringen",
                                    value: "Endre forsikringen"
                                },
                                {
                                    label: "Avslutte forsikringen",
                                    value: "Avslutte forsikringen"
                                },
                                {
                                    label: "Skadesaker",
                                    value: "Skadesaker"
                                }
                            ]
                        },
                        {
                            label: "Er det noe mer du vil legge til?",
                            type: "text"
                        }
                    ]
                }
            }}
        />
    );
};
