import { useEffect } from "react";
import { track, Trackingkey } from "src/tracking";
import { useBruker } from "@features/bruker/queries";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { Typography } from "@components/Typography";
import { getProductByCode, useCustomerNumberCheck, useIsManaged } from "./utils";
import { Product, useAvailableProducts } from "./queries";
import { HomePageCTA as BilHomePageCTA } from "./bil";
import { HomePageCTA as ReiseHomePageCTA } from "./reise";

import "./style.scss";

interface HomePageOffersProps {
    list?: boolean;
    homepage?: boolean;
}

export const HomePageOffers = ({ list, homepage }: HomePageOffersProps) => {
    const { bruker } = useBruker();
    const { isLoading, products } = useAvailableProducts();

    const trackProductView = (product: Product) => {
        track({
            hendelse: Trackingkey.SeTilbud,
            produkt: product.productCode,
            organisasjon: bruker?.gjeldendeOrganisasjonsnummer ?? ""
        });
    };

    const V05Object = getProductByCode(products, AvtaleProduktID.BIL_NAERING);
    const Y44Object = getProductByCode(products, AvtaleProduktID.REISE_NERING);

    useEffect(() => {
        if (V05Object?.productCode) trackProductView(V05Object);

        if (Y44Object?.productCode) trackProductView(Y44Object);
    }, [V05Object, Y44Object]); // eslint-disable-line

    // Ikke vis innganger dersom kunden ikke har et gyldig kundenummer i NICE.
    const hasNiceCustomerNumber = useCustomerNumberCheck();

    // Ikke vis innganger for meglede kunder.
    const isManaged = useIsManaged();

    if (isLoading || !hasNiceCustomerNumber || isManaged) return null;

    return list ? (
        <>
            <ReiseHomePageCTA shouldShow={Y44Object?.flowPath} asListItem />
            <BilHomePageCTA shouldShow="/kjop/bil" asListItem />
        </>
    ) : (
        <section className={"offers two-col-grid"}>
            {homepage && <Typography variant="heading-3">Aktuelt</Typography>}
            <ReiseHomePageCTA shouldShow={Y44Object?.flowPath} />
            <BilHomePageCTA shouldShow="/kjop/bil" />
        </section>
    );
};
