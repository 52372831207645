import React, { type ElementType } from "react";
import { Link, LinkProps } from "react-router-dom";
import { ButtonProps } from "@fremtind/jkl-button-react";
import classnames from "classnames";
import { track, Trackingkey } from "../../tracking";

interface LinkButtonProps extends LinkProps, Pick<ButtonProps<ElementType>, "density"> {
    lenkeId?: string;
}

const buttonFactory = (type: "primary" | "secondary" | "tertiary"): React.FC<LinkButtonProps> => {
    // displayname settes i komponent definisjonen
    // eslint-disable-next-line react/display-name
    return ({ className, density, lenkeId, onClick, children, ...linkProps }) => {
        const componentClassName = classnames(className, "jkl-button", `jkl-button--${type}`);

        return (
            <Link
                data-density={density}
                className={componentClassName}
                {...linkProps}
                onClick={(e) => {
                    if (lenkeId) {
                        track({ hendelse: Trackingkey.LenkeTrykk, lenkeId });
                    }

                    if (onClick) {
                        onClick(e);
                    }
                }}
            >
                {children}
            </Link>
        );
    };
};

export const PrimaryLinkButton = buttonFactory("primary");
PrimaryLinkButton.displayName = "PrimaryLinkButton";

export const SecondaryLinkButton = buttonFactory("secondary");
SecondaryLinkButton.displayName = "SecondaryLinkButton";

export const TertiaryLinkButton = buttonFactory("tertiary");
TertiaryLinkButton.displayName = "TertiaryLinkButton";
