import type React from "react";
import { type AgreementCoverageTable, type AgreementDetailObject } from "src/model/gen";
import { AgreementCoverageCard } from "./AgreementCoverageCard";

import "./AgreementCoverageList.scss";
import { coverageInfo } from "./CoverageInfo";

type Props = {
    objects: AgreementDetailObject[];
};

export const AgreementCoverageList: React.FC<Props> = ({ objects }) => {
    const coverageTables: AgreementCoverageTable[] = objects.map((object) => object.coverageTable);

    const convertedToCardFormat: any = [];

    coverageTables?.map((cTable) => {
        cTable.rows.map((row) => {
            convertedToCardFormat.push({
                title: row?.row[0].value,
                infoTags: {
                    value: row?.row[1].value,
                    type: row?.row[1].type,
                    name: cTable.columnNames[1]
                }
            });
        });
    });

    return (
        <ul className="agreement-coverage-list">
            {convertedToCardFormat.map((coverage: any) => (
                <li>
                    <AgreementCoverageCard
                        key={coverage.title}
                        title={coverage.title}
                        information={coverageInfo[coverage.title?.toLowerCase().replace(/\s/g, "")]?.description}
                        infoTags={coverage.infoTags}
                    />
                </li>
            ))}
        </ul>
    );
};
