import { AgreementDetails } from "src/model/gen";
import { Typography } from "@components/Typography";
import { BreadCrumbs } from "@components/BreadCrumbs";
import { PopupTip } from "@fremtind/jkl-tooltip-react";
import { LenkeId, useExternalLinks } from "src/common/lenker";
import { SecondaryLinkButton } from "@components/Button";
import { formatValuta } from "@fremtind/jkl-formatters-util";
import "./AgreementDetailsHeader.scss";
import { InfoMessageBox } from "@fremtind/jkl-message-box-react";
import { AgreementPreviewFeedback } from "@features/agreements/context/AgreementPreviewFeedback";
import { EditAgreementButton } from "./EditAgreementButton/EditAgreementButton";
import { CopyToClipboardButton } from "@components/CopyToClipboardButton";

interface Props {
    agreementDetails: AgreementDetails;
}

export const AgreementDetailsHeader = ({ agreementDetails }: Props) => {
    const hasLagringsforsikring = agreementDetails.stateDescription === "Lagringsforsikring";
    const hasLagringsforsikringString = hasLagringsforsikring ? " (Lagringforsikring)" : "";

    const yearlyPrice = formatValuta(agreementDetails.annualPremium);
    const trafikkforsikringsavgift = agreementDetails.additionalInfo?.find(
        (o: any) => o.description === "Trafikkforsikringsavgift til staten"
    )?.value;
    const forsikringsPris = agreementDetails.additionalInfo?.find(
        (o: any) => o.description === "Forsikringspris"
    )?.value;
    const agreementPeriod = `${new Date(agreementDetails.startDate).toLocaleDateString("nb-NO", {
        year: "numeric",
        month: "short",
        day: "numeric"
    })} - ${new Date(agreementDetails.endDate).toLocaleDateString("nb-NO", {
        year: "numeric",
        month: "short",
        day: "numeric"
    })}`;

    const { getLinkByLinkId } = useExternalLinks();
    const newClaimLink = getLinkByLinkId(LenkeId.meldSkade)?.href;

    return (
        <header className="agreement-details__header">
            <BreadCrumbs />
            <InfoMessageBox fullWidth className="preview-message">
                Vi tester nye avtalesider for bilforsikringer.
                <AgreementPreviewFeedback />
            </InfoMessageBox>
            <div className="agreement-details__header__content">
                <div className="agreement-details__header__content__info">
                    <div className="agreement-details__header__content__info__headings">
                        <Typography component="h1" variant="heading-1" className="jkl-spacing-xs--bottom">
                            {agreementDetails.objects.length === 1
                                ? `${agreementDetails.objects[0].name} ${hasLagringsforsikringString}`
                                : `${agreementDetails.productName} ${hasLagringsforsikringString}`}
                        </Typography>
                        <Typography variant="body" className="agreement-details__header__content__info__description">
                            {agreementDetails.objects.length === 0
                                ? `${agreementDetails.objects[0].name} ${hasLagringsforsikringString}`
                                : `${agreementDetails.productName} ${hasLagringsforsikringString}`}
                        </Typography>
                    </div>
                    <div className="agreement-details__header__content__info__list">
                        <div>
                            <Typography bold>Avtalenummer</Typography>
                            <CopyToClipboardButton
                                textToCopy={agreementDetails.agreementId}
                                actionLabel="Kopier avtalenummer"
                            />
                        </div>
                        <div>
                            <Typography bold>Årlig pris</Typography>
                            <Typography>
                                <span style={{ marginRight: "5px" }}>{yearlyPrice}</span>
                                <PopupTip
                                    content={`Forsikringspris: ${forsikringsPris} Trafikkforsikringsavgift til staten: ${trafikkforsikringsavgift} kr`}
                                ></PopupTip>
                            </Typography>
                        </div>
                        <div>
                            <Typography bold>Avtaleperiode</Typography>
                            <CopyToClipboardButton textToCopy={agreementPeriod} actionLabel="Kopier avtaleperiode" />
                        </div>
                    </div>
                    <div className="agreement-details__header__content__info__buttons">
                        {newClaimLink && (
                            <SecondaryLinkButton to={newClaimLink} target="_blank" density={"comfortable"}>
                                Meld skade
                            </SecondaryLinkButton>
                        )}

                        <EditAgreementButton agreementDetails={agreementDetails} />
                    </div>
                </div>
                <img src="../../bedrift/static/images/products/bilnaering.png" alt="" />
            </div>
        </header>
    );
};
