import { Typography } from "@components/Typography";
import { List, ListItem } from "@fremtind/jkl-list-react";

export type KjopCodeAllowedValue = {
    code: string;
    value: string;
    allowed?: boolean;
};

export type BundleType = "V05_TOPPKASKO" | "V05_KASKO_LEIEBIL" | "V05_KASKO" | "V05_MINIKASKO" | "V05_ANSVAR";

type CoverageLinks = {
    [key in BundleType]: {
        linkText: string;
        linkURL: string;
        rentalAgreementLinkText?: string;
        rentalAgreementLinkURL?: string;
    };
};

type TermsLinks = {
    ipid: string;
    toolsInsurance: string;
} & CoverageLinks;

export const termsLinks: TermsLinks = {
    ipid: "https://dokument.fremtind.no/ipid/IPID_Bil_Naering.pdf",
    toolsInsurance: "https://dokument.fremtind.no/vilkar/fremtind/bm/mobilitet/Vilkar_Verktoy_i_bil.pdf",
    V05_ANSVAR: {
        linkText: "Vilkårsdokument ansvarsforsikring (PDF)",
        linkURL: "https://dokument.fremtind.no/vilkar/fremtind/bm/mobilitet/Vilkar_Ansvar.pdf"
    },
    V05_MINIKASKO: {
        linkText: "Vilkårsdokument minikasko (PDF)",
        linkURL: "https://dokument.fremtind.no/vilkar/fremtind/bm/mobilitet/Vilkar_Minikasko_Bil_naering.pdf"
    },
    V05_KASKO: {
        linkText: "Vilkårsdokument kasko (PDF)",
        linkURL: "https://dokument.fremtind.no/vilkar/fremtind/bm/mobilitet/Vilkar_Kasko_Bil_naering.pdf"
    },
    V05_KASKO_LEIEBIL: {
        linkText: "Vilkårsdokument kasko (PDF)",
        linkURL: "https://dokument.fremtind.no/vilkar/fremtind/bm/mobilitet/Vilkar_Kasko_Bil_naering.pdf",
        rentalAgreementLinkURL: "https://dokument.fremtind.no/vilkar/fremtind/bm/mobilitet/Vilkar_Leiebil.pdf",
        rentalAgreementLinkText: "Vilkår for leiebil (PDF)"
    },
    V05_TOPPKASKO: {
        linkText: "Vilkårsdokument toppkasko (PDF)",
        linkURL: "https://dokument.fremtind.no/vilkar/fremtind/bm/mobilitet/Vilkar_Toppkasko_Bil_naering.pdf"
    }
};

export const deductibleOptions = [
    {
        value: "10000",
        label: "10 000 kr"
    },
    {
        value: "12000",
        label: "12 000 kr"
    },
    {
        value: "15000",
        label: "15 000 kr"
    },
    {
        value: "20000",
        label: "20 000 kr"
    },
    {
        value: "30000",
        label: "30 000 kr"
    }
];

export const coveredIncidents = [
    {
        title: "Ansvar",
        description: (
            <List>
                <ListItem>
                    Dekker skade på andre personers kjøretøy, ting og eiendom med inntil 100 mill. kroner.
                </ListItem>
                <ListItem>Dekker skade på personer med ubegrenset beløp.</ListItem>
                <ListItem>
                    Dekker utgifter til rettshjelp med inntil 100 000 kr hvis du som bileier blir part i en tvist.
                    F.eks. i forbindelse med kjøp eller salg av bil.
                </ListItem>
            </List>
        )
    },
    {
        title: "Fører- og passasjerulykke",
        description: (
            <Typography>
                Hvis fører eller passasjerer blir skadet i en ulykke, utbetaler vi inntil{" "}
                <span className="whitespace-nowrap">200 000 kr</span> per person ved medisinsk invaliditet.
            </Typography>
        )
    },
    {
        title: "Brann, tyveri og hærverk",
        description: (
            <Typography>
                Dekker skade som følge av brann, lynnedslag og eksplosjon. Dekker tyveri og skader på bilen ved forsøk
                på tyveri. Dekker også hærverkskader.
            </Typography>
        )
    },
    {
        title: "Veihjelp",
        description: (
            <Typography>
                Rask hjelp ved motorstopp, tomt batteri eller annen plutselig hendelse som gjør at du ikke kan kjøre
                videre. Dekningen gjelder i hele Europa. Egenandelen er{" "}
                <span className="whitespace-nowrap">750 kr.</span>
            </Typography>
        )
    },
    {
        title: "Glasskade",
        description: (
            <Typography>
                Dekker skifte eller reparasjon ved skade på frontrute, vindu og glasstak, f.eks. ved steinsprut. Det er
                ingen egenandel hvis ruten kan repareres. Hvis ruten må byttes er egenandelen{" "}
                <span className="whitespace-nowrap">3 000 kr.</span>
            </Typography>
        )
    },
    {
        title: "Fastmontert tilleggsutstyr",
        description: (
            <>
                <List>
                    <ListItem>
                        Toppkasko: Dekker fastmontert utstyr med inntil{" "}
                        <span className="whitespace-nowrap">50 000kr.</span>
                    </ListItem>
                    <ListItem>
                        Minikasko og kasko: Dekker fastmontert utstyr med inntil{" "}
                        <span className="whitespace-nowrap">20 000 kr.</span>
                    </ListItem>
                </List>
                <Typography>Eksempler: foliering, ekstralys, fastmonterte reoler.</Typography>
            </>
        )
    },
    {
        title: "Eiendeler i bilen",
        description: (
            <List>
                <ListItem>
                    Toppkasko: Dekker personlige eiendeler oppbevart i bilen med inntil{" "}
                    <span className="whitespace-nowrap">20 000 kr.</span>
                </ListItem>
                <ListItem>
                    Minikasko og kasko: Dekker personlige eiendeler oppbevart i bilen med inntil{" "}
                    <span className="whitespace-nowrap">10 000 kr.</span>
                </ListItem>
            </List>
        )
    },
    {
        title: "Skade på egen bil",
        description: (
            <List>
                <ListItem>Dekker skade på bilen din som følge av større uhell som utforkjøring og kollisjon.</ListItem>
                <ListItem>Dekker feilfylling av drivstoff.</ListItem>
                <ListItem>Dekker også skade som følge av andre plutselig ytre årsaker, f.eks. naturskader. </ListItem>
            </List>
        )
    },
    {
        title: "Ny bil ved totalskade",
        description: (
            <List>
                <ListItem>
                    Toppskasko: Dekker ny bil ved totalskade hvis bilen er inntil 3 år gammel og har kjørt under{" "}
                    <span className="whitespace-nowrap">100 000 km.</span>
                </ListItem>
                <ListItem>
                    Kasko: Dekker ny bil ved totalskade hvis bilen er inntil 1 år gammel og har kjørt under{" "}
                    <span className="whitespace-nowrap">15 000 km.</span>
                </ListItem>
            </List>
        )
    },
    {
        title: "Leiebil",
        description: (
            <List>
                <ListItem>
                    Toppskasko: Dekker leiebil i inntil <span className="whitespace-nowrap">30 dager</span> ved skader
                    som er dekket av forsikringen.
                </ListItem>
                <ListItem>
                    Kasko med leiebil: Dekker leiebil i inntil <span className="whitespace-nowrap">25 dager</span> ved
                    skader som er dekket av forsikringen.
                </ListItem>
            </List>
        )
    },
    {
        title: "Bilnøkkel",
        description: (
            <Typography>
                Dekker tap av bilnøkkel med inntil <span className="whitespace-nowrap">8000 kr.</span> Egenandelen er{" "}
                <span className="whitespace-nowrap">750 kr.</span>
            </Typography>
        )
    },
    {
        title: "Parkeringsskade uten bonustap",
        description: (
            <Typography>
                Blir bilen din bulket mens den står parkert, og du ikke vet hvem som er ansvarlig, får du ikke bonustap.
                Bilen må være under <span className="whitespace-nowrap">6 år,</span> og du må vite når og hvor skaden
                skjedde.
            </Typography>
        )
    },
    {
        title: "Maskinskade",
        description: (
            <Typography>
                Dekker plutselig og uforutsett skade på motor, girkasse og drivverk. Gjelder inntil bilen er 10 år og
                ikke har kjørt mer enn <span className="whitespace-nowrap">250 000 km,</span>
                det som inntreffer først. Det trekkes en egenandel basert på bilens kilometerstand. Se vilkår.
            </Typography>
        )
    }
];

const ANSVAR_TERMS = coveredIncidents.slice(0, 2);
const MINIKASKO_TERMS = coveredIncidents.slice(0, 7);
const KASKO_TERMS = coveredIncidents.slice(0, 9);
const KASKO_LEIEBIL_TERMS = coveredIncidents.slice(0, 10);
const TOPPKASKO_TERMS = coveredIncidents;

export const coverageTerms = [
    {
        title: "Toppkasko",
        code: "V05_TOPPKASKO",
        terms: TOPPKASKO_TERMS
    },
    {
        title: "Kasko m/leiebil",
        code: "V05_KASKO_LEIEBIL",
        terms: KASKO_LEIEBIL_TERMS
    },
    {
        title: "Kasko",
        code: "V05_KASKO",
        terms: KASKO_TERMS
    },
    {
        title: "Minikasko",
        code: "V05_MINIKASKO",
        terms: MINIKASKO_TERMS
    },
    {
        title: "Ansvar",
        code: "V05_ANSVAR",
        terms: ANSVAR_TERMS
    }
];

export const toolInsuranceAmountOptions = [
    {
        value: "25000",
        label: "25 000 kr"
    },
    {
        value: "50000",
        label: "50 000 kr"
    },
    {
        value: "75000",
        label: "75 000 kr"
    },
    {
        value: "100000",
        label: "100 000 kr"
    },
    {
        value: "150000",
        label: "150 000 kr"
    },
    {
        value: "200000",
        label: "200 000 kr"
    },
    {
        value: "250000",
        label: "250 000 kr"
    }
];

export const hasUnwantedUsageArea = (areaOfUse: string | undefined, allAreas: KjopCodeAllowedValue[]) => {
    const areaObject = allAreas.find((area) => area.code === areaOfUse);

    return !areaObject?.allowed;
};
