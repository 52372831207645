import type React from "react";
import { InfoCard } from "@fremtind/jkl-card-react";
import { Typography } from "@components/Typography";
import { Tag } from "@fremtind/jkl-tag-react";
import type { AgreementField } from "src/model/gen";
import "./AgreementCoverageCard.scss";

interface Props {
    title: string;
    subtitle?: string;
    information?: any;
    infoTags: {
        name: string;
        value: string;
        type: AgreementField["type"];
    };
}

export const AgreementCoverageCard: React.FC<Props> = ({ title, subtitle, infoTags, information }) => {
    const formatValue = (value: string, type: AgreementField["type"]) => {
        if (type === "CURRENCY") {
            return Number(value).toLocaleString("nb-NO", {
                style: "currency",
                currency: "NOK",
                maximumFractionDigits: 0
            });
        }

        if (type === "GVALUE") {
            return `${Number(value).toLocaleString("nb-NO", { maximumFractionDigits: 1 })} G`;
        }

        if (value === "") {
            return "Ubegrenset";
        }

        return value;
    };

    return (
        <InfoCard className="agreement-details__coverage__card">
            <Typography bold>{title}</Typography>
            {information && <Typography>{information}</Typography>}
            {subtitle && <Typography>{subtitle}</Typography>}
            <div className="agreement-details__coverage__card__tags">
                <Tag key={infoTags.name} className="agreement-details__coverage__card__tag">
                    {infoTags.name}: {formatValue(infoTags.value, infoTags.type)}
                </Tag>
            </div>
        </InfoCard>
    );
};
